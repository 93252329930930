<template>
        <div v-if="data.is_empty" :class="`col-md-${data.col}`" style="background:transparent;border:0;box-shadow:0;">
            <div class="card card-flush">
            
            </div>
        </div>
        <div v-else :class="`col-md-${data.col} ${data.top_class}`">
            <div class="card card-flush">
            <div class="row">
                <div class="col-sm-4">
                <i :class="data.icon"></i>
                </div>
                <div v-if="data.value_2" class="col-sm-8 pull-right">

                <el-tooltip :disabled="!data.value_2_tooltip"
                  class="box-item"
                  :class="{'cursor-pointer': data.value_2_tooltip}"
                  effect="light"
                  placement="top"
                  :show-after="500"
                  :hide-after="0"
                  >
                    <template #content>{{  data.value_2_tooltip }}</template>
                    <span
                      :style="`color: ${data.style_2} !important; font-size: ${
                      getTextWidth(data.value_2) > 92
                          ? '22px'
                          : getTextWidth(data.value_2) > 82
                          ? '24px'
                          : getTextWidth(data.value_2) > 70
                          ? '26px'
                          : getTextWidth(data.value_2) > 60
                          ? '28px'
                          : '34px'
                      }`"
                      :class="data.class_2"
                    >
                    {{ data.value_2 }}
                    </span>
                  </el-tooltip>
                </div>
            </div>
            <div></div>

            <div class="card-header bg-light-white">
                <div class="card-title d-flex flex-column">
                <span class="pt-1 fw-bold fs-5 text-gray-700"
                        v-html="data.label"
                    />
                    
                <div class="d-flex align-items-center digit-value">
                    <span
                    @click="redirectToDashboardDetailsReport"
                    :style="`color: ${data.style_1}; font-size: ${
                        getTextWidth(data.value_1) > 92
                        ? '22px'
                        : getTextWidth(data.value_1) > 82
                        ? '24px'
                        : getTextWidth(data.value_1) > 70
                        ? '26px'
                        : getTextWidth(data.value_1) > 60
                        ? '28px'
                        : '34px'
                    }`"
                    :class="data.class_1"
                    >
                        {{ data.value_1 }}
                    </span>
                </div>
                </div>
            </div>
            </div>
        </div>
      
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  data: {
    type: Function,
    required: true,
  },
  start: {
    type: String,
    required: true,
  },
  end: {
    type: String,
    required: true,
  },
});

function redirectToDashboardDetailsReport() {
  if (props.data && props.data.link) {
    const query = props.data.link
      .replace("DATE_START", props.start)
      .replace("DATE_END", props.end);
    window.open(`/#/report/dashboard-details?${query}`);
  }
}

function getTextWidth(text, font) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  context.font = font || getComputedStyle(document.body).font;

  return context.measureText(text).width;
}
</script>
