<template>
      <div 
      v-loading="loading" 
      element-loading-background="rgba(122, 122, 122, 0.1)"
      element-loading-text="Please wait"
      :element-loading-spinner="svg"
      element-loading-svg-view-box="-10, -10, 50, 50"
      style="z-index: 1;"
    >
        <div class="row gy-5 g-xl-8 dashboard-section">
            <AddCustomerModal />
            <div class="col-xxl-12 dashboard-filter-row">
                <div class="card">
                    <div class="card-body w-100">
                        <div class="container p-0 w-100">
                            <div class="d-flex align-items-center justify-content-between w-100 m-0 admin-header-top">
                                <DaysTab @click="date_years.reset()" />
                                <DateYears 
                                    ref="date_years"
                                    :over-due-tasks="dueDate.all"
                                    :task-today="dueDate.today"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Admin 
                v-if="adminDashboard || route.name === 'admin-dashboard'"
                :over-views="overview"
                :sales-person-info="salesPersonInfo"
                :statistics="statistics"
                :other-info="otherInfo"
                @pagination="handlePagination"
            />
            <SaSmTl 
                v-if="SASMTLDashboard && route.name !== 'admin-dashboard'"
                :datas="store.getters.getCarDBoxDashboard(saSmTl, user_id, role_id)"
                :loading="loading"
            />
            <Om 
                v-if="omDashboard && route.name !== 'admin-dashboard'"
                :datas="store.getters.getCarDBoxOmDashboard(saSmTl, user_id)"
                :loading="loading"
            />
            <Underwriter 
                v-if="role_id == 4"
                :datas="store.getters.getCardBoxUnderwriterDashboard(underwriter, user_id)"
            />
            <Accounts 
                v-if="role_id == 6"
                :datas="store.getters.getCarBoxAccountDashboard(accounts)"
            />
            <Marketing 
                v-if="MarketingAdminDashboard"
                :datas="marketing"
            />
        </div>
      </div>
</template>

<script setup>
import { onMounted, ref, watch, onUnmounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import DaysTab from '@/views/NEW/Dashboard/Models/DaysTab.vue'
import DateYears from '@/views/NEW/Dashboard/Models/DateYears.vue'
import Admin from '@/views/NEW/Dashboard/Views/Admin.vue'
import SaSmTl from '@/views/NEW/Dashboard/Views/SasSmsTls.vue'
import Om from '@/views/NEW/Dashboard/Views/Om.vue'
import Underwriter from '@/views/NEW/Dashboard/Views/Underwriter.vue'
import Accounts from '@/views/NEW/Dashboard/Views/Accounts.vue'
import Marketing from '@/views/NEW/Dashboard/Views/Marketing.vue'
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { useUser, useRoleBasedConditions } from '@/store/composable/User'
import { dFilters } from '@/store/composable/Dashboard'
import { useStore } from 'vuex'
import { useUpdateCustomerData } from '@/store/composable/Customer'
import  moment from "moment";
import { adminOverview , adminSalesPersonInfo, adminStatistics, adminOtherInfo, dueDateCounts, sasSmsTlsDef, underwriterDef, accountsDef, marketingAdminDef } from '@/core/utils/DefaultData'
import { leadsTaskP } from '@/store/composable/Listing'
import { Mutations } from "@/store/enums/StoreEnums";
import { useRoute } from 'vue-router'

const overview = ref(adminOverview)
const salesPersonInfo = ref(adminSalesPersonInfo)
const statistics = ref(adminStatistics)
const otherInfo = ref(adminOtherInfo)
const saSmTl = ref(sasSmsTlsDef)
const underwriter = ref(underwriterDef)
const dueDate = ref(dueDateCounts)
const accounts = ref(accountsDef)
const marketing = ref(marketingAdminDef)
const loading = ref(true)
const { role_id, user_id} = useUser()
const { adminDashboard, SASMTLDashboard, MarketingAdminDashboard, omDashboard } = useRoleBasedConditions()
const store = useStore()
const date_years = ref()
const route = useRoute()

onMounted(() => {
    setCurrentPageTitle("Dashboard");

    if(adminDashboard || route.name === 'admin-dashboard') {
        dFilters.value.type = 'today'
        dFilters.value.start_date = moment().format('YYYY-MM-DD')
        dFilters.value.end_date = moment().format('YYYY-MM-DD')
    } else {
        dFilters.value.type = 'month'
        dFilters.value.start_date = moment().startOf('month').format('YYYY-MM-DD')
        dFilters.value.end_date = moment().endOf('month').format('YYYY-MM-DD')
    }
})

onUnmounted(() => {
    dFilters.value.start_date = null
    dFilters.value.end_date = null
    dFilters.value.type = 'today'
})

watch(() => dFilters.value, newValue => {
    let payloads = {}

    if(adminDashboard || route.name === 'admin-dashboard') {
        if(newValue.tab == 0) {
            payloads = {
                linkType: 'dashboard-overview',
                start_date: newValue.start_date + ' 00:00:00',
                end_date: newValue.end_date + ' 23:59:59',
            }
        }
        else if(newValue.tab == 1) {
            payloads = {
                linkType: 'dashboard-sales-person-info',
                start_date: newValue.start_date,
                end_date: newValue.end_date,
                table: 'top-sales-person'
            }
        } else if(newValue.tab == 2) {
            payloads = {
                linkType: 'dashboard-sales-statistics',
                start_date: newValue.start_date,
                end_date: newValue.end_date,
            }
        } else if(newValue.tab == 3) {
            payloads = {
                linkType: 'dashboard-sales-others',
                start_date: newValue.start_date,
                end_date: newValue.end_date,
            }
        }
    }
    if((SASMTLDashboard || omDashboard) && route.name !== 'admin-dashboard') {
        payloads = {
            linkType: 'dashboard-SA-SM-TL',
            start_date: newValue.start_date,
            end_date: newValue.end_date,
        }
    }

    if(role_id == 4) {
        payloads = {
            linkType: 'dashboard-underwriter',
            start_date: newValue.start_date,
            end_date: newValue.end_date,
        }
    }

    if(role_id == 6 && route.name !== 'admin-dashboard') {
        payloads = {
            linkType: 'dashboard-accounts',
            start_date: newValue.start_date,
            end_date: newValue.end_date,
        }
    }

    if(MarketingAdminDashboard && route.name !== 'admin-dashboard') {
        payloads = {
            linkType: 'dashboard-marketing',
            start_date: newValue.start_date,
            end_date: newValue.end_date,
        }
    }
    

    callDashboard(payloads)
}, {
    deep: true
})


async function callDashboard(payloads) {
    loading.value = true

    generateDates()
    // if(adminDashboard && dFilters.value.tab == 0) {
    //     store.dispatch('DASHBOARD_OVERVIEWS', payloads)
    // } else {
        const response = await useUpdateCustomerData(payloads)
        loading.value = false

        if(response.status == 200) {
            if(adminDashboard || route.name === 'admin-dashboard') {
                if(dFilters.value.tab == 0) overview.value = response.data.data
                if(dFilters.value.tab == 1) salesPersonInfo.value = response.data.data
                else if(dFilters.value.tab == 2) statistics.value = response.data.data
                else if(dFilters.value.tab == 3) otherInfo.value = response.data.data
            }

            if((SASMTLDashboard || omDashboard) && route.name !== 'admin-dashboard') {
                saSmTl.value = response.data.data
            }

            if(role_id == 4) {
                underwriter.value = response.data.data
                const dueDateCount = underwriter.value[0]
                dueDate.value.all = dueDateCount.overdue
                dueDate.value.today = dueDateCount.assigned_today
            }

            if(role_id == 6 && route.name !== 'admin-dashboard') {
                accounts.value = response.data.data
            }

            if(MarketingAdminDashboard && route.name !== 'admin-dashboard') {
                marketing.value =response.data.data
            }
        }
    // }

    if(SASMTLDashboard || omDashboard) {
        const tt = {
                per_page: 9999,
                task_due_dates: "all",
                customer_status_id: -1,
                agent: 'all',
                lead_status_id: -1,
                keyword: "",
                start_date: "",
                end_date: "",
                is_hot_lead: false,
                is_hot_renewal_lead: false,
                page: 1
            }
            const carDueDateCount = await useUpdateCustomerData({ linkType: 'car-task-due-date-count', ...tt })
            dueDate.value = carDueDateCount.data.data.count
        }
}

async function handlePagination(page, linkType, start, end) {
    
    const data = {
        page,
        linkType,
        start_date: start,
        end_date: end
    }

    const response = await useUpdateCustomerData(data)
    if(dFilters.value.tab == 3) {
        otherInfo.value = assignOtherInfoData(otherInfo.value, response.data.data, linkType)
    }
}

function assignOtherInfoData(data, replace, linkType) {
    let obj = ''
    switch(linkType) {
        case 'user-logs': obj = 'userRecentLog'; break;
        case 'new-leads': obj = 'leadsNew'; break;
        case 'pending-leads': obj = 'leadsPending'; break;
        case 'lost-leads': obj = 'leadsLost'; break;
        case 'cod-transaction': obj = 'listCodTransactions'; break;
        case 'online-transaction': obj = 'listOnlineTransactions'; break;
        case 'direct-transaction': obj = 'listDirectTransactions'; break;
        default: obj = 'userRecentLog'; break;
    }

    return {
        ...data,
        [obj]: replace
    }
}

function generateDates() {
    
    const date = new Date();
    let dateTitle = ''
    if(dFilters.value.type == 'month') {
        dateTitle = '(01 - '+new Date().getDate() + ' ' +new Date().toLocaleString('en-us',{ month:'long', year:'numeric'})+')';
    }
    else if (dFilters.value.type == 'lastmonth') {
        const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        dateTitle = '(01 - '+lastDay.getDate() + ' ' +lastDay.toLocaleString('en-us',{ month:'long', year:'numeric'})+')';
    }
        
    else if (dFilters.value.type == 'PreviousMonth') {
        const lastDay = new Date(date.getFullYear(), date.getMonth() -1, 0);
        dateTitle = '(01 - '+lastDay.getDate() + ' ' +lastDay.toLocaleString('en-us',{ month:'long', year:'numeric'})+')';
    }
    else if(!dFilters.value.type) {
        const startTS = new Date(dFilters.value.start_date);
        const endTS = new Date(dFilters.value.end_date);
        dateTitle = '('+startTS.getDate()+' '+startTS.toLocaleString('en-us',{ month:'long', year:'numeric'})+' - '+endTS.getDate() + ' ' +endTS.toLocaleString('en-us',{ month:'long', year:'numeric'})+')';
    }
    
    store.commit(Mutations.SET_DASHBOARD_DATE_TITLE, dateTitle)
}

</script>
<style scoped>
.top-title {
    font-size: 17px !important;
    line-height: 20px !important;
    color: #5E6278 !important;
    font-weight: 500;
}
.row div {
  align-items: unset;
}
</style>
